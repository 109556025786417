<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row class="mb-1">
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-input
              v-model="filterOrder"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Order')"
            />

            <b-form-input
              v-model="filterInvoice"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Invoice')"
            />
            <b-form-input
              v-model="filterStartDate"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('Start Date')"
            />
            <b-form-input
              v-model="filterEndDate"
              class="d-inline-block mr-50 advance-search"
              :placeholder="$t('End Date')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t("Pagesize") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="first"
              @click="whichPage"
            >
              {{ $t("First") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="prior"
              @click="whichPage"
            >
              {{ $t("Prior") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-1"
              id="next"
              @click="whichPage"
            >
              {{ $t("Next") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              id="last"
              @click="whichPage"
            >
              {{ $t("Last") }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="invoicedata"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="Loading..."
      >
        <template #cell(bookedInvoiceNumber)="data">
          <!-- <a 
                        :href="'https://engine.netsupport.dk:7080/account/v1/economicpdf/'+mytoken+'?number='+data.item.bookedInvoiceNumber" target="_blank">
                        {{ data.item.bookedInvoiceNumber }}
                    </a> -->
          <a
            v-b-modal="'modal-scrollable' + data.item.bookedInvoiceNumber"
            href="javascript:void(0);"
            @click="showmodel(data.item.bookedInvoiceNumber)"
          >
            {{ data.item.bookedInvoiceNumber }}
          </a>
          <b-modal
            :id="'modal-scrollable' + data.item.bookedInvoiceNumber"
            :title="$t('Overdue-Invoice PDF')"
            ok-title="Cancel"
            size="lg"
            ok-only
            centered
            @hidden="onHidden"
          >
            <iframe
              :src="showpdf"
              frameBorder="0"
              width="750"
              height="700"
            ></iframe>
          </b-modal>
        </template>
        <template #cell(notes)="data" class="check-main">
          <div v-if="!data.item.notes"></div>
          <div
            v-else-if="
              data.item.notes.heading != '' || data.item.notes.heading != null
            "
          >
            {{ data.item.notes.heading }}
          </div>
          <div
            v-else-if="
              data.item.notes.textline != '' || data.item.notes.textline != null
            "
          >
            {{ data.item.notes.textline }}
          </div>
          <div
            v-else-if="
              data.item.references.other != '' ||
                data.item.references.other != null
            "
          >
            {{ data.item.references.other }}
          </div>
          <div v-else></div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal="'modal-scrollable2' + data.item.bookedInvoiceNumber"
              href="javascript:void(0);"
              @click="showmodel(data.item.bookedInvoiceNumber)"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">{{ $t("View PDF") }}</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-modal
            :id="'modal-scrollable2' + data.item.bookedInvoiceNumber"
            :title="$t('All-Invoice PDF')"
            ok-title="Cancel"
            size="lg"
            ok-only
            centered
            @hidden="onHidden"
          >
            <iframe
              :src="showpdf"
              frameBorder="0"
              width="750"
              height="700"
            ></iframe>
          </b-modal>
        </template>
      </b-table>
      <div class="text-center" v-if="!nodata">
        <b-spinner variant="primary" label="Text Centered"></b-spinner>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("Showing") }} {{ dataMeta.from }} {{ $t("to") }}
              {{ dataMeta.to }} {{ $t("of") }} {{ dataMeta.of }}
              {{ $t("entries") }}</span
            >
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  VBModal,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import i18n from "@/libs/i18n/index";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    vSelect,
    BSpinner,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      invoicedata: [],
      paginationdata: [],
      showpdf: "",

      tableColumns: [
        { key: "number", label: i18n.t("number"), sortable: true },
        { key: "invoiceDate", label: i18n.t("invoiceDate"), sortable: true },
        { key: "currencyCode", label: i18n.t("currency"), sortable: true },
        { key: "orderNumber", label: i18n.t("orderNumber"), sortable: true },
        { key: "remainingAmount", label: i18n.t("remaining") },
        {
          key: "totalAmountIncludingTax",
          label: i18n.t("total"),
        },
        { key: "status", label: i18n.t("status") },
      ],
      perPage: 5,
      currentPage: 1,
      totalInvoices: "",
      dataMeta: { from: 1, to: this.perPage, of: this.totalInvoices },
      filteroffset: 0,
      perPageOptions: [5, 10, 20, 40, 60, 80, 100],
      filterStartDate: "",
      filterEndDate: "",
      filterInvoice: "",
      filterOrder: "",
      nodata: false,
    };
  },
  watch: {
    perPage: function() {
      localStorage.setItem("pagesize", this.perPage);
      this.getInvoicelist();
    },
    filterOrder: function(val) {
      this.filterOrder = val;
      this.getInvoicelist();
    },
    filterInvoice: function(val) {
      this.filterInvoice = val;
      this.getInvoicelist();
    },
    filterStartDate: function(val) {
      this.filterStartDate = val;
      this.getInvoicelist();
    },
    filterEndDate: function(val) {
      this.filterEndDate = val;
      this.getInvoicelist();
    },
  },
  updated() {
    const f = this.perPage * (this.currentPage - 1);
    this.dataMeta.from = f + 1;
    var checkrecords = this.perPage * this.currentPage;
    if (checkrecords > this.totalInvoices) {
      this.dataMeta.to = this.totalInvoices;
    } else {
      this.dataMeta.to = this.perPage * this.currentPage;
    }
  },
  created() {
    this.mytoken = localStorage.getItem("token");
    if (this.mytoken == "" || this.mytoken == null) {
      localStorage.setItem("token", "");
      this.$router.push({ name: "login" });
    } else {
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/account/v1/checktokendebtorbc/" +
          this.mytoken,
      };
      axios(config)
        .then((response) => {
          if (response.data.token.status == true) {
            this.getInvoicelist();
          }
        })
        .catch(function(error) {
          if (error.response.status == 404) {
            localStorage.setItem("token", "");
            localStorage.removeItem("username");
            window.location.href = "/login";
          }
        });
    }
  },
  mounted() {
    const currentDate = new Date();

    const year = currentDate.getFullYear() - 1;
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day}`;

    this.filterStartDate = formattedDate;

    const currentDate1 = new Date();

    const year1 = currentDate.getFullYear();
    const month1 = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day1 = String(currentDate.getDate()).padStart(2, "0");

    const formattedDate1 = `${year1}-${month1}-${day1}`;
    this.filterEndDate = formattedDate1;
  },

  methods: {
    getInvoicelist() {
      this.mytoken = localStorage.getItem("token");

      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/bcportal/v1/invoices/" +
          this.mytoken +
          "?offset=" +
          this.filteroffset +
          "&pagesize=" +
          this.perPage +
          "&overdue=yes" +
          "&startdate=" +
          this.filterStartDate +
          "&enddate=" +
          this.filterEndDate +
          "&order=" +
          this.filterOrder +
          "&invoice=" +
          this.filterInvoice,
      };
      axios(config)
        .then((response) => {
          console.log("overdue", response);
          this.nodata = true;
          this.invoicedata = JSON.parse(JSON.stringify(response.data.invoices));
          this.currentPage = response.data.economic.pagination.skipPages + 1;

          this.paginationdata = response.data.economic.pagination;
          this.dataMeta.of = response.data.economic.pagination.results;
          this.totalInvoices = response.data.economic.pagination.results;

          //first button
          if (this.paginationdata.firstPage) {
            document.getElementById("first").disabled = false;
          } else {
            document.getElementById("first").disabled = true;
          }
          //prior button
          if (this.paginationdata.previousPage) {
            document.getElementById("prior").disabled = false;
          } else {
            document.getElementById("prior").disabled = true;
          }
          //next button
          if (this.paginationdata.nextPage) {
            document.getElementById("next").disabled = false;
          } else {
            document.getElementById("next").disabled = true;
          }
          //last button
          if (this.paginationdata.lastPage) {
            document.getElementById("last").disabled = false;
          } else {
            document.getElementById("last").disabled = true;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    whichPage(e) {
      //console.log(this.paginationdata);
      if (e.target.id == "first") {
        this.filteroffset = this.paginationdata.firstPage;
        this.getInvoicelist();
      } else if (e.target.id == "prior") {
        this.filteroffset = this.paginationdata.previousPage;
        this.getInvoicelist();
      } else if (e.target.id == "next") {
        this.filteroffset = this.paginationdata.nextPage;
        this.getInvoicelist();
      } else if (e.target.id == "last") {
        this.filteroffset = this.paginationdata.lastPage;
        this.getInvoicelist();
      }
    },
    onHidden() {
      this.showpdf = "";
    },
    showmodel(number) {
      //console.log(number);
      this.mytoken = localStorage.getItem("token");
      var config = {
        method: "get",
        url:
          "https://engine.netsupport.dk:7080/account/v1/economicpdf/" +
          this.mytoken +
          "?number=" +
          number,
      };
      axios(config)
        .then((response) => {
          if (response.data.pdfFileName) {
            this.showpdf = response.data.pdfFileName;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.check-btn {
  transform: scale(02);
  width: 12px;
  height: 12px;
}
.invoice-filtercheck-select {
  width: 40%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
